import requests from './request';


//获取资讯
export const getNewsList = (data) => requests({ url: `/api/News/GetNewsList`, data: data, method: "post" })
//获取联系方式
export const getNewsDetail = (data) => requests({ url: `/api/News/GetNewsDetail`, data: data, method: "post" })
//获取图片验证码
export const getImageCode = (data) => requests({ url: `/api/Common/GetImageCode`, data: data, method: "post" })
//用户注册
export const register = (data) => requests({ url: `/api/User/Register`, data: data, method: "post" })
//登录/注册 发送短信验证码
export const sendCode = (data) => requests({ url: `/api/User/SendCode`, data: data, method: "post" })
//手机号+密码登录
export const LoginByPassword = (data) => requests({ url: `/api/User/LoginByPassword`, data: data, method: "post" })
//获取团队成员
export const getTeamPeople = (data) => requests({ url: `/api/Content/GetTeamPeople`, data: data, method: "post" })
//获取常见问题
export const getQuestionList = (data) => requests({ url: `/api/Content/GetQuestionList`, data: data, method: "post" })
//获取联系方式
export const getContactServer = (data) => requests({ url: `/api/Content/GetContactServer`, data: data, method: "post" })
//获取活动列表
export const getActivityList = (data) => requests({ url: `/api/Activity/GetActivityList`, data: data, method: "post" })
//获取活动详情
export const getActivityDetail = (data) => requests({ url: `/api/Activity/GetActivityDetail`, data: data, method: "post" })
//获取活动详情
export const GetPrivacyText = (data) => requests({ url: `/api/Content/GetPrivacyText`, data: data, method: "post" })

//获取活动详情
export const GetUserServiceText = (data) => requests({ url: `/api/Content/GetUserServiceText`, data: data, method: "post" })
//获取活动详情
export const GetBottomList = (data) => requests({ url: `/api/Content/GetBottomList`, data: data, method: "post" })
//获取活动详情
export const GetUserInfo = (data) => requests({ url: `/api/User/GetUserInfo`, data: data, method: "post" })
//获取活动详情
export const GetShareQrcode = (data) => requests({ url: `/api/User/GetShareQrcode`, data: data, method: "post" })
//获取活动详情
export const GetAboutText = (data) => requests({ url: `/api/Content/GetAboutText`, data: data, method: "post" })
//获取活动详情
export const GetContactLis = (data) => requests({ url: `/api/Common/GetContactList`, data: data, method: "post" })
//获取活动详情
export const UpdateUserInfo = (data) => requests({ url: `/api/User/UpdateUserInfo`, data: data, method: "post" })
//获取活动详情
export const UploadPicture = (data) => requests({ url: `/api/Upload/UploadPicture`, data: data, method: "post" })
//获取活动详情
export const LoginByCode = (data) => requests({ url: `/api/User/LoginByCode`, data: data, method: "post" })
//获取活动详情
export const GetOfficialList = (data) => requests({ url: `/api/Common/GetOfficialList`, data: data, method: "post" })
//获取活动详情
export const GetGoodsServiceText = (data) => requests({ url: `/api/Content/GetGoodsServiceText`, data: data, method: "post" })
//获取活动详情
export const UpdateRealInfo = (data) => requests({ url: `/api/User/UpdateRealInfo`, data: data, method: "post" })

//获取商品列表
export const GetGoodsList = (data) => requests({ url: `/api/Goods/GetGoodsList`, data: data, method: "post" })
//获取商品详情
export const GetGoodsDetail = (data) => requests({ url: `/api/Goods/GetGoodsDetail`, data: data, method: "post" })
//提交订单
export const SubmitOrder = (data) => requests({ url: `/api/Order/SubmitOrder`, data: data, method: "post" })
//检测订单是否支付
export const CheckOrderStatus = (data) => requests({ url: `/api/Order/CheckOrderStatus`, data: data, method: "post" })
//调用微信支付
export const OrderWechatPay = (data) => requests({ url: `/api/Order/OrderWechatPay`, data: data, method: "post" })
//调用支付宝支付
export const OrderAliPay = (data) => requests({ url: `/api/Order/OrderAliPay`, data: data, method: "post" })
