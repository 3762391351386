import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index.js';


import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
Vue.use(ElementUI);

import headerNav from '@/components/headerNav.vue';
import footerNav from '@/components/footerNav.vue';
import iconz from '@/components/iconz.vue';
import imagez from '@/components/imagez.vue';
import announcementItem from '@/components/announcementItem.vue';
import exhibitorsItem from '@/components/exhibitorsItem.vue';
import members from '@/components/members.vue';
Vue.component('header-nav', headerNav);
Vue.component('footer-nav', footerNav);
Vue.component('icon-z', iconz);
Vue.component('image-z', imagez);
Vue.component('announcement-item', announcementItem);
Vue.component('exhibitors-item', exhibitorsItem);
Vue.component('members', members);
// 导入初始化样式
import './assets/style/index.scss';
import './assets/style/iconfont.css';
import './assets/style/flex.css';
import './assets/style/umi.css';
function routerTo(name, query={}) {
  this.$router.push({
    name: name,
    query: query
  })
  window.scrollTo({
    top: 0,
    behavior: 'smooth'
  })
}
import * as API from '@/api';
Vue.prototype.$routerTo = routerTo
Vue.config.productionTip = false

new Vue({
  render: h => h(App),
  router,
  beforeCreate() {
    Vue.prototype.$API = API;
  }
}).$mount('#app')
