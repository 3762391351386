import Vue from "vue"
import vueRouter from "vue-router"

Vue.use(vueRouter)

let originPush = vueRouter.prototype.push;
let originreplace = vueRouter.prototype.replace;

// 重写push
vueRouter.prototype.push = function (location, resolve, rereject) {
  if (resolve && rereject) {
    originPush.call(this, location, resolve, rereject)
  } else {
    originPush.call(this, location, () => {
    }, () => {
    })
  }
}
// 重写replace
vueRouter.prototype.replace = function (location, resolve, rereject) {
  if (resolve && rereject) {
    originreplace.call(this, location, resolve, rereject)
  } else {
    originreplace.call(this, location, () => {
    }, () => {
    })
  }
}

const routes = [

  // 首页
  {
    path: '/',
    name: 'home',
    component: () => import('@/views/index.vue'),
    children: [{
      path: "/",
      name: "index",
      component: () => import("../views/index/index.vue")
    }, // 常见问题
    {
      path: '/record/faq',
      name: 'faq',
      component: () => import('@/views/record/faq.vue'),
    },

    // 联系客服
    {
      path: '/record/contact-us',
      name: 'contact',
      component: () => import('@/views/record/contact.vue'),
    },

    // 关于我们
    {
      path: '/record/about-us',
      name: 'aboutUs',
      component: () => import('@/views/record/aboutUs.vue'),
    },
    // 关于我们
    {
      path: '/record/channels',
      name: 'channels',
      component: () => import('@/views/record/channels.vue'),
    },
    // 关于我们
    {
      path: '/record/official',
      name: 'official',
      component: () => import('@/views/record/official.vue'),
    },
    // 新闻资讯
    {
      path: '/news/index',
      name: 'news',
      component: () => import('@/views/news/index.vue'),
    },
    // 公告资讯
    {
      path: '/news/all',
      name: 'allNews',
      component: () => import('@/views/news/all.vue'),
    },
    // 最新公告
    {
      path: '/news/announce',
      name: 'announce',
      component: () => import('@/views/news/announce.vue'),
    },
    // 返图画廊
    {
      path: '/news/gallery',
      name: 'gallery',
      component: () => import('@/views/news/gallery.vue'),
    },
    // 参展展商
    {
      path: '/news/stall',
      name: 'stall',
      component: () => import('@/views/news/stall.vue'),
    },
    // 新闻资讯
    {
      path: '/news/detail',
      name: 'newsDetail',
      component: () => import('@/views/news/detail.vue'),
    },
    // 报名表一览
    {
      path: '/form/home',
      name: 'form',
      component: () => import('@/views/form/home.vue'),
    },
    // 我的二维码
    {
      path: '/ticket/home',
      name: 'myQrcode',
      component: () => import('@/views/ticket/home.vue'),
    },
    // 兽牌上传
    {
      path: '/ticket/badge',
      name: 'badge',
      component: () => import('@/views/ticket/badge.vue'),
    },
    // 升舱服务
    {
      path: '/ticket/upgrade',
      name: 'upgrade',
      component: () => import('@/views/ticket/upgrade.vue'),
    },
    // 票务转让
    {
      path: '/ticket/transfer',
      name: 'transfer',
      component: () => import('@/views/ticket/transfer.vue'),
    },
    // 同住人绑定
    {
      path: '/ticket/registration',
      name: 'registration',
      component: () => import('@/views/ticket/registration.vue'),
    },
    // CDKEY兑换
    {
      path: '/ticket/cdkey',
      name: 'cdkey',
      component: () => import('@/views/ticket/cdkey.vue'),
    },
    // 订单中心
    {
      path: '/order/index',
      name: 'order',
      component: () => import('@/views/order/index.vue'),
    },
    // 订单详情
    {
      path: '/order/detail',
      name: 'orderDetail',
      component: () => import('@/views/order/detail.vue'),
    },
    // 核销订单
    {
      path: '/order/writeOff',
      name: 'writeOff',
      component: () => import('@/views/order/writeOff.vue'),
    },
    // 个人信息
    {
      path: '/user/index',
      name: 'user',
      component: () => import('@/views/user/index.vue'),
    },
    // 我的票务
    {
      path: '/ticket/myTicket',
      name: 'myTicket',
      component: () => import('@/views/ticket/myTicket.vue'),
    },
    // 我的票务
    {
      path: '/shop/home',
      name: 'shop',
      component: () => import('@/views/shop/home.vue'),
    }, ,
    // 下单
    {
      path: '/shop/order',
      name: 'orderTicket',
      component: () => import('@/views/shop/order.vue'),
    },
    // 我的票务
    {
      path: '/record/privacy',
      name: 'privacy',
      component: () => import('@/views/record/privacy.vue'),
    },
    // 我的票务
    {
      path: '/user/realname',
      name: 'realname',
      component: () => import('@/views/user/realname.vue'),
    }
    ]
  },

  // 登入
  {
    path: '/login',
    name: 'login',
    component: () => import('@/views/auth/login.vue'),
  },

  // 注册
  {
    path: '/register',
    name: 'register',
    component: () => import('@/views/auth/signup.vue'),
  },

  // 忘记密码(找回密码)
  {
    path: '/forgetPwd',
    name: 'forgetPwd',
    component: () => import('@/views/auth/forgotPwd.vue'),
  },
  // 核销订单
  {
    path: '/order/qrcode',
    name: 'qrcode',
    component: () => import('@/views/order/qrcode.vue'),
  },


];
const router = new vueRouter({
  routes
})

// 路由守卫判断跳转的是不是home，是的话就判断是否有menu，没有的话就不跳转
router.afterEach(async (to, from) => {
  console.log(to, from)

  let token = localStorage.getItem("token") || ''

  let names = ['shop', 'myTicket', 'user', 'order', 'cdkey', 'registration', 'transfer', 'upgrade', 'badge', 'myQrcode', 'form']


  if (!token && names.indexOf(to.name) != -1) {
    router.push({
      name: "login"
    })
  }
})


export default router;
