//对于axios进行二次封装
import axios from "axios";
//底下的代码也是创建axios实例
let requests = axios.create({
    //基础路径
    baseURL: "",
    // https://zhuan.igetv.com/nMdupLJkZw.php
    //请求不能超过5S
    timeout: 10000,
});

const cancelList = [
]

let pending = []; //声明一个数组用于存储每个请求的取消函数和axios标识
let cancelToken = axios.CancelToken;
let removePending = (config) => {
    for (let p in pending) {
        //由于不同的环境ip或者baseUrl不一样，这里需要取出相同的部分
        //比如 192.168.0.132:3000/vaCantRoom/listPage和192.168.0.40:3000/vaCantRoom/listPage
        //我们只取vaCantRoom/listPage,如上cancelList的第一个
        //这里的正则表达式是根据我们后台微服务的名字来写的，具体内容需要看你们自己的后台什么样
        let formatU = pending[p].u.split("&")[0].replace(/((neo|mmt)-([a-zA-Z0-9-]*)\/)/, "")
      
        if (cancelList.indexOf(formatU) > -1) {
            if (pending[p].u === config.url + '&' + config.method) {
                //当当前请求在数组中存在时执行函数体
                pending[p].f(); //执行取消操作
                pending.splice(p, 1);
            }
        }
    }
}

//请求拦截器----在项目中发请求（请求没有发出去）可以做一些事情
requests.interceptors.request.use((config) => {

    removePending(config); //在一个axios发送前执行一下取消操作
    config.cancelToken = new cancelToken((c) => {
        // 唯一标识，用来判断是否是同一个请求
        pending.push({ u: config.url + '&' + config.method, f: c });
    });
    //需要携带token带给服务器
    if (localStorage.getItem("token")) {
        let token = localStorage.getItem("token")
        if (!config.data) {
            config.data = new Object()
        }

        config.headers.token = token

    }


    return config;

});


export default requests;
