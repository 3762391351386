<template>
    <div class="gallery-img">
        <div v-for="(item, index) in list" :key="index" class="col-lg-3 iamge_box cursor-pointer">
            <el-image :src="item.image" :initial-index="0" fit="cover" v-if="item.content_type == 2"
                @click="link(item.link)" />
            <el-image :src="item.image" :initial-index="0" fit="cover" :preview-src-list="[item.image]"
                v-if="item.content_type == 1" />
            <div class="mask dis_c" v-if="item.content_type == 2">
                <svg class="flex-shrink-0 lg:mt-[0.125rem] mt-[0.25rem]" viewBox="0 0 1024 1024"
                    xmlns="http://www.w3.org/2000/svg" width="25" height="25">
                    <path
                        d="M624.32 484.48a32 32 0 010 55.04l-160 96A32 32 0 01416 608V416a32 32 0 0148.32-27.52zM480 472.64v78.72L545.92 512z"
                        fill="#fff"></path>
                    <path
                        d="M896 768V256H128v512zm0 64H128a64 64 0 01-64-64V256a64 64 0 0164-64h768a64 64 0 0164 64v512a64 64 0 01-64 64z"
                        fill="#fff"></path>
                    <path d="M192 224a32 32 0 0164 0v544a32 32 0 01-64 0z" fill="#fff"></path>
                    <path
                        d="M224 384a32 32 0 010 64H96a32 32 0 010-64zm0 192a32 32 0 010 64H96a32 32 0 010-64zm544-352a32 32 0 0164 0v544a32 32 0 01-64 0z"
                        fill="#fff"></path>
                    <path d="M800 448a32 32 0 010-64h128a32 32 0 010 64zm0 192a32 32 0 010-64h128a32 32 0 010 64z"
                        fill="#fff"></path>
                </svg>
                <div class="line-clamp-1">{{ item.title }}</div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: ['icon', 'size', 'list'],
    methods: {
        link(path) {
            window.open(path, '_block')
        }
    }
}
</script>
<style lang="scss">
.iamge_box {
    padding: .25rem !important;
    position: relative;
}

.gallery-img {
    display: flex;
    flex-wrap: wrap;

    .el-image {
        width: 420px;
        height: 300px;
        border-radius: 0.5rem;
    }
}

/* 当屏幕分辨率大于1024像素时，应用下面的样式 */
@media screen and (min-width: 1024px) {

    .gallery-img .el-image {

        width: 100%;
        height: 300px;
        border-radius: 0.5rem;
    }
}

@media screen and (max-width: 1024px) {
    .gallery-img .el-image {
        width: 100%;
        height: 150px;
        border-radius: 0.5rem;
    }
}

.mask {
    position: absolute;
    bottom: 1rem;
    left: 50%;
    transform: translateX(-50%);
    width: calc(100% - 0.5rem);
    padding: 1rem;
    box-sizing: border-box;
    color: #fff;
    background-color: rgba(0, 0, 0, 0.5);
    border-radius: 0.5rem;
}
</style>