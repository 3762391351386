<template>
    <div>
        <div class="iconfont" :class="icon" :style="'font-size:' + size + 'rem'"></div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: ['icon', 'size']
}
</script>
<style></style>