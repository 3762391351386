<template>
    <div class="col-lg-3">
        <div class="w-full inline-block lg:p-[1.5rem] p-[1rem] rounded-[20px] bg-white h-full animate__animated animate__fadeIn" >
            <img :src="data.image" />
            <h5 class="text__20 font-medium mb1">{{data.title}}</h5>
            <p class="text__16 mb2 text">
                <span class="font-medium">{{data.content}}</span>
            </p>
        </div>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: ['data']
}
</script>

<style lang="scss" scoped>
.exhibitors-contet {
    width: 100%;
    height: 100%;
    padding: 1.5rem;
    border-radius: 20px;
    box-shadow: 0 0 5px 1px #efeded;

    img {
        width: 100%;
        margin-bottom: 1rem;
        border-radius: 1rem;
        object-fit: cover;
    }

    .text {
        display: flex;
        gap: 0.25rem;
        color: rgb(159, 159, 159);
        letter-spacing: 0.5rem;
        line-height: 2;
    }
}

/* 当屏幕分辨率大于1024像素时，应用下面的样式 */
@media screen and (min-width: 1024px) {
 
    .exhibitors-contet img {
        height: 250px;
    }
}

@media screen and (max-width: 1024px) {
    .exhibitors-contet img {
        height: 120px;
    }
}
</style>