<template>
    <div>
        <span class="cursor-pointer w-full lg:p-[2rem] p-[1rem] rounded-[12px] lg:rounded-[20px] bg-white flex lg:flex-row flex-col justify-between hover:shadow-lg animate__animated animate__fadeIn" @click="detail(data.news_id)">
            <div class="flex flex-col lg:w-3/5 pr-[0rem] lg:pr-[2rem] pb-[0.75rem] lg:pb-[0rem]">
                <div class="flex-1 flex flex-col gap-2">
                    <div style="display: flex; gap: 16px">
                        <div class="h-9 w-3 rounded-lg bg-Mblue"></div>
                        <div class="text-2xl font-semibold text-Mblue">{{ data.type == 1 ? '新闻' : '公告' }}</div>
                    </div>
                    <div class="text-2xl font-semibold text-Mblue" style="padding: 8px 0">
                        {{ data.title }}
                    </div>
                    <div class="text__18 text-gray-600 line_3" style="line-height:1.5">
                        {{ data.content }}
                    </div>
                </div>
                <div class="text__18 lg:mt-0 mt-1 text-gray-500">发布于{{ data.created_at }}</div>
            </div>
            <img :src="data.image" class="lg:w-2/5 rounded-[12px] lg:rounded-[20px] object-cover h-52 lg:h-64 lg:my-[0rem] my-[0rem] bg-gray-400" />
        </span>
    </div>
</template>

<script>
export default {
    data() {
        return {

        }
    },
    props: ['data'],
    methods: {
        detail(id) {
            this.$router.push({
                name: 'newsDetail',
                query: {
                    news_id: id
                }
            })
        }
    }
}
</script>

<style lang="scss" scoped>
.item {
    padding: 2rem;
    border-radius: 20px;
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap;
    transition: all 0.2s;

    &:hover {
        cursor: pointer;
        box-shadow: 0 0 5px 1px #999;
    }

    .announcement-content {
        padding-right: 2rem;
        padding-bottom: 0;
        display: flex;
        flex-direction: column;

        .content {
            display: flex;
            flex-direction: column;
            flex: 1 1;
            gap: 0.5rem;

            .content-icon {
                background-color: rgb(67, 92, 255);
                width: 12px;
                height: 36px;
                border-radius: 0.5rem;
            }
        }

        .time {
            margin-top: 0.25rem;
        }
    }

    img {
        border-radius: 20px;
        height: 16rem;
        margin: 0;
        object-fit: cover;
    }
}
</style>