<template>
    <div class="flex flex-wrap gap-3">
        <div class="flex flex-wrap gap-3" v-for="(item, index) in list" :key="index">
            <div class="flex">
                <img class="h-32 w-32 mx-8" :src="item.image">
                <div class="flex flex-col text-black flex-1 gap-1 w-24">
                    <div class="text__20" style="margin: 1rem 0;"><span style="font-weight: bold;">{{item.name}}</span></div>
                    <div class="text__14">{{item.position}}</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
export default {
    data() {
        return {

        }
    },
    props: ['icon', 'size', 'list']
}
</script>

<style>

</style>